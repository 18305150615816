import api from './api';
import {AppResponse} from "../models/common/app-response";
import { AddDepartmentDto } from '../models/departments/addDepartmentDto';
import { EditDepartmentDto } from '../models/departments/editDepartmentDto';
import { PaginatedResponse } from '../models/common/paginated-response';
import { DepartmentsInfoFilter } from '../models/departments/departmentsInfoFilter';
import { DepartmentShortDto } from '../models/departments/departmentShortDto';
import { DepartmentDetailsDto } from '../models/departments/departmentDetailsDto';

class DepartmentsService {

    baseUrl: string = "/api/v1/departments";

    get = (companyId: number) => {
        return api.get<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/by-company-id/${companyId}`);
    }  

    getAvailable = (companyId: number) => {
        return api.get<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/by-company-id/${companyId}/available`);
    }

    getByFilter = (companyId: number, filter?: DepartmentsInfoFilter) => {
        return api.get<AppResponse<PaginatedResponse<DepartmentDetailsDto>>>(`${this.baseUrl}/company-info/${companyId}`, { params: filter });
    }

    getById = (id: number) => {
        return api.get<AppResponse<DepartmentDetailsDto>>(`${this.baseUrl}/${id}`);
    }
    
    getByCompanyIds = (companyIds: number[]) => {
        return api.post<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/by-company-ids`, companyIds);
    }

    add = (data : AddDepartmentDto) => {
        return api.post<AppResponse<number>>(this.baseUrl, data);
    }

    edit = (data : EditDepartmentDto) => {
        return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, data);
    }
}

export const departmentsService = new DepartmentsService();