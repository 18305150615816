import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, FormControl, InputLabel, MenuItem, Select, Link, IconButton, Grid } from '@mui/material';
import { DateUtils } from '../../helpers/dateUtils';
import CompanyAutocomplete from '../common/CompanyAutocomplete';
import { MedicalJournalShortDto } from '../../models/medicalCheckups/medicalJournalShortDto';
import { medicalJournalsService } from '../../services/medicalJournalsService';
import { CreateJournalDialog } from './CreateJournalDialog';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import ErrorAlert from '../common/ErrorAlert';
import Spinner from '../common/Spinner';
import { DatePickerFilter } from '../common/DatePickerFilter';
import TmPlus from '../../icons/TmPlus';

export const MedicalJournalTable = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [openCreateDialog, setopenCreateDialog] = useState(false);
    const [data, setData] = useState<MedicalJournalShortDto[]>([]);
    const [page, setPage] = useState(0);
    const [dataCount, setDataCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [filter, setFilter] = useState({
        createdDate: null as Date | null,
        company: null as CompanyShortDto | null,
        isSigned: ''
    });

    const refreshData = () => {
        medicalJournalsService.getByFilter({
            createdDate: filter.createdDate,
            companyId: filter.company ? filter.company.id : null,
            isSigned: filter.isSigned,
            page: page,
            pageSize: rowsPerPage,
        })
            .then(r => {
                setData(r.data.data.items);
                setDataCount(r.data.data.count);
            })
            .catch(console.log);
    };

    useEffect(() => {
        refreshData();
        const interval = setInterval(refreshData, 30000);
        return () => clearInterval(interval);
    }, [page, rowsPerPage, filter]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page when rowsPerPage changes
    };

    const filterChanged = (name: string, value: any) => {
        setFilter({ ...filter, [name]: value });
    };

    function handleIsSignedFilterChanged(e: { target: { name: string; value: any; }; }): void {
        filterChanged(e.target.name, e.target.value);
    }

    const handleDownload = async (journalId: number) => {
        setLoading(true);
        medicalJournalsService.getDownloadLink(journalId)
            .then(r => {
                const url = r.data.data.link;
                window.location.href = url;
            })
            .catch(e => showAlert(e.response.data.message ?? e.message))
            .finally(() => setLoading(false));
    };

    const showAlert = (errorMessage: string) => {
        setError(errorMessage);
    }
    
    function renderStatus(result: MedicalJournalShortDto) {
        const strValue = result.isSigned ? "Подписан" : "Не подписан";
        let color = !result.isSigned ? '#ec4a71' : '#5bba64';
        let backgroundColor = !result.isSigned ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{backgroundColor: {backgroundColor}, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px"}}>{strValue}</Box>;
    };
    
    const onCloseCreateJournalDialog = () => {
        setopenCreateDialog(false);
        refreshData();
    }

    return (
        <Box >
            {error && (
                <ErrorAlert
                    message={error}
                    onClose={() => setError('')}
                    autoCloseDuration={10000} />
            )}
            {loading
                ? <Spinner />
                : (
                    <div>
                        <Grid container pb={1} mt={2.5}>
                            <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                                <Box display="flex" alignItems="center" marginBottom={1}>
                                    <DatePickerFilter
                                        label="Дата"
                                        name="createdDate"
                                        value={filter.createdDate}
                                        onChange={(value, name) => filterChanged(name, value)}
                                        style={{ width: 200, marginRight: 10, marginTop: 4 }}
                                    />

                                    <CompanyAutocomplete
                                        company={filter.company}
                                        onCompanyChange={(e) => filterChanged('company', e)}
                                        style={{ width: 200, marginRight: 10 }}
                                    />

                                    <FormControl variant="outlined" margin="dense" size="small" style={{ width: 200, marginRight: 10 }}>
                                        <InputLabel id="is-signed-select-label">Статус</InputLabel>
                                        <Select
                                            labelId="is-signed-select-label"
                                            id="is-signed-select"
                                            name="isSigned"
                                            value={filter.isSigned}
                                            label="Статус"
                                            onChange={handleIsSignedFilterChanged}
                                            sx={{borderRadius: "10px",}}
                                        >
                                            <MenuItem key={1} value={""}>Все</MenuItem>
                                            <MenuItem key={2} value={"false"}>Журналы на подпись</MenuItem>
                                            <MenuItem key={3} value={"true"}>Подписанные журналы</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Box>
                            </Grid>
                            <Grid item xs={2} style={{ display: 'inline-block', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton sx={{display: "flex"}} className="list-item" onClick={() => setopenCreateDialog(true)}>
                                        <TmPlus fill="none" stroke="white"/>&nbsp;
                                        <Typography fontWeight="bold" pr={1}>
                                            Сформировать
                                        </Typography>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        
                        <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>ID</Typography></TableCell>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Клиент</Typography></TableCell>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>ФИО медика</Typography></TableCell>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Дата</Typography></TableCell>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Сформировавший пользователь</Typography></TableCell>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Статус</Typography></TableCell>
                                        <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Журнал</Typography></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell sx={{ fontWeight: "bold" }}>{item.id}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{item.companyName}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{item.medicFio}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{DateUtils.formatDateTime(item.createdDate)}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{item.createdByFio}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{renderStatus(item)}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}><Link onClick={() => handleDownload(item.id)} style={{ cursor: 'pointer' }}>Скачать</Link></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography>Всего: {dataCount}</Typography>
                            <TablePagination
                                rowsPerPageOptions={[15, 30, 50]}
                                component="div"
                                count={dataCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(event: any, page: number) => setPage(page)}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={"Строк на странице:"}
                                labelDisplayedRows={({ from, to, count }: any): string => `Показано ${from}-${to} из ${count}`}
                            />
                        </Box>
                        <CreateJournalDialog
                            open={openCreateDialog}
                            onClose={onCloseCreateJournalDialog}
                        />
                    </div>
                )}
        </Box>
    );
};