import api from './api';
import {AppResponse} from "../models/common/app-response";
import { TerminalShortInfoDto } from '../models/terminals/terminalShortInfoDto';
import { PaginatedResponse } from '../models/common/paginated-response';
import { TerminalFilter } from '../models/terminals/terminalFilter';
import { EditTerminalDto } from '../models/terminals/editTerminalDto';
import { TerminalDetailsDto } from '../models/terminals/terminalDetailsDto';
import { AddTerminalDto } from '../models/terminals/addTerminalDto';
import { SearchTerminalFilter } from '../models/terminals/searchTerminalFilter';
import { SearchTerminalDto } from '../models/terminals/searchTerminalDto';
import { DepartmentShortDto } from '../models/departments/departmentShortDto';

class TerminalsService {

    baseUrl: string = "/api/v1/terminals";

    getByFilter = (filter? : TerminalFilter) => {
        return api.get<AppResponse<PaginatedResponse<TerminalShortInfoDto>>>(this.baseUrl,{ params: filter });
    }

    search = (filter?: SearchTerminalFilter) => {
        return api.get<AppResponse<PaginatedResponse<SearchTerminalDto>>>(`${this.baseUrl}/search`, { params: filter });
    }

    getById = (id: number) => {
        return api.get<AppResponse<TerminalDetailsDto>>(`${this.baseUrl}/${id}`);
    }
    
    getByCompanyId = (companyId: number) => {
        return api.get<AppResponse<TerminalShortInfoDto[]>>(`${this.baseUrl}/${companyId}/terminals`);
    }

    add = (data : AddTerminalDto) => {
        return api.post<AppResponse<number>>(this.baseUrl, data);
    }

    edit = (data : EditTerminalDto) => {
        return api.put<AppResponse<number>>(`${this.baseUrl}/${data.id}`, data);
    }

    linkToCompany = (terminalId: number, companyId : number) => {
        return api.post<AppResponse<number>>(`${this.baseUrl}/${terminalId}/company/${companyId}`);
    }

    linkToDepartments = (terminalId: number, departmentIds : number[]) => {
        return api.post<AppResponse<number>>(`${this.baseUrl}/${terminalId}/departments`, departmentIds);
    }

    updateIntegrityCheck = (terminalId: number, isEnabled: boolean) => {
        return api.post<AppResponse<void>>(`${this.baseUrl}/${terminalId}/sw-integrity-check`, { performSwIntegrityCheck: isEnabled });
    }
}

export const terminalsService = new TerminalsService();