import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Box } from "@mui/material";
import DatePickerLocalized from "../common/DatePickerLocalized";
import { MedicalJournalFormat } from "../../models/enums/medicalJournalFormat";
import { medicalJournalsService } from "../../services/medicalJournalsService";
import CompanyAutocomplete from "../common/CompanyAutocomplete";
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import ErrorAlert from '../common/ErrorAlert';
import TmEmptyButton from '../common/buttons/TmEmptyButton';
import TmFullButton from '../common/buttons/TmFullButton';
import { DateUtils } from '../../helpers/dateUtils';

interface CreateJournalDialogProps {
    open: boolean;
    onClose: () => void;
}

export const CreateJournalDialog: React.FC<CreateJournalDialogProps> = ({ open, onClose }) => {
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        fromDate: null as string | Date | null,
        toDate: null as string | Date | null,
        company: null as CompanyShortDto | null,
        format: MedicalJournalFormat.Xlsx,
    });

    const handleSubmit = () => {
        medicalJournalsService.create({
            from: DateUtils.dateToISO(formData.fromDate),
            to: DateUtils.dateToISO(formData.toDate),
            companyId: formData.company!.id!,
            format: formData.format!,

        })
            .then(response => onClose())
            .catch(e => {
                console.log(e);
                const error = e.response.data.message ?? e.message;
                setError(error);
            });
    };


    const formChanged = (name: string, value: any) => {
        setFormData({ ...formData, [name]: value });
    };

    const isFormValid = (): boolean => {
        const isValid = !!(formData.fromDate && formData.toDate && formData.company && formData.company.id && formData.format);
        return isValid;
    }

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: "25px" } }}>
            <DialogTitle variant='h4' sx={{ textAlign: 'center' }}>Формирование журнала медосмотров</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DatePickerLocalized
                                label="Начало периода"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={(value, name) => formChanged(name, value)}
                                style={{ width: '100%', marginRight: 10, marginTop: 5 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePickerLocalized
                                label="Конец периода"
                                name="toDate"
                                value={formData.toDate}
                                onChange={(value, name) => formChanged(name, value)}
                                style={{ width: '100%', marginRight: 10, marginTop: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CompanyAutocomplete
                                company={formData.company}
                                onCompanyChange={(c) => formChanged('company', c)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <RadioGroup row name='format' value={formData.format} onChange={(e) => formChanged(e.target.name, e.target.value)}>
                                    <FormControlLabel disabled value={MedicalJournalFormat.Pdf} control={<Radio />} label="PDF" />
                                    <FormControlLabel disabled value={MedicalJournalFormat.Docx} control={<Radio />} label="DOCX" />
                                    <FormControlLabel value={MedicalJournalFormat.Xlsx} control={<Radio />} label="XLSX" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', paddingBottom: "2em"}}>
                <TmEmptyButton onClick={onClose} width='45%'>
                    Отмена
                </TmEmptyButton>
                <TmFullButton onClick={handleSubmit} width='45%' disabled={!isFormValid()}>
                    Сформировать
                </TmFullButton>
            </DialogActions>
            {error && (
                <ErrorAlert
                    message={error}
                    onClose={() => setError('')}
                />
            )}
        </Dialog>
    );
};

export default CreateJournalDialog;
