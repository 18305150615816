import React, { useEffect, useReducer } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Grid,
    TableSortLabel,
    Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { DriverShortDto } from '../../models/drivers/driverShortDto';
import { driversService } from '../../services/driversService';
import { DateUtils } from '../../helpers/dateUtils';
import CompanyAutocomplete from '../common/CompanyAutocomplete';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import { EditDriverModal } from './edit/EditDriverModal';
import { ImportDriversDialog } from './ImportDriversDialog';
import { ShowForPermission } from '../common/permissions/ShowForPermission';
import TmPlus from '../../icons/TmPlus';
import { ROUTES } from '../../constants/routes';
import { SortingOrder } from '../../models/enums/sortingOrder';
import { CategoryType } from '../../models/enums/categoryType';
import { PermissionType } from '../../models/enums/permissionType';
import DepartmentAutocomplete from '../common/DepartmentAutocomplete';
import { DepartmentShortDto } from '../../models/departments/departmentShortDto';

const PAGE_REFRESH_INTERVAL = 30000;

const initialState = {
    drivers: [] as DriverShortDto[],
    page: 0,
    rowsPerPage: 15,
    total: 0,
    loading: true,
    sortColumn: 'employeeNumber',
    sortOrder: SortingOrder.Asc,
    filter: {
        employeeNumber: '',
        fio: '',
        company: null as CompanyShortDto | null,
        department: null as DepartmentShortDto | null,
        hideDismissed: null as boolean | null,
    },
    isAddPopupOpen: false,
    isImportPopupOpen: false,
};

type State = typeof initialState;

type Action =
    | { type: 'SET_DRIVERS'; payload: { drivers: DriverShortDto[]; total: number } }
    | { type: 'SET_PAGE'; payload: number }
    | { type: 'SET_ROWS_PER_PAGE'; payload: number }
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_FILTER'; payload: Partial<State['filter']> }
    | { type: 'SET_SORT'; payload: { column: string; order: SortingOrder } }
    | { type: 'TOGGLE_ADD_POPUP' }
    | { type: 'TOGGLE_IMPORT_POPUP' };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_DRIVERS':
            return { ...state, drivers: action.payload.drivers, total: action.payload.total, loading: false };
        case 'SET_PAGE':
            return { ...state, page: action.payload, loading: true };
        case 'SET_ROWS_PER_PAGE':
            return { ...state, rowsPerPage: action.payload, page: 0, loading: true };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_FILTER':
            return { ...state, filter: { ...state.filter, ...action.payload }, page: 0, loading: true };
        case 'SET_SORT':
            return { ...state, sortColumn: action.payload.column, sortOrder: action.payload.order, loading: true };
        case 'TOGGLE_ADD_POPUP':
            return { ...state, isAddPopupOpen: !state.isAddPopupOpen };
        case 'TOGGLE_IMPORT_POPUP':
            return { ...state, isImportPopupOpen: !state.isImportPopupOpen };
        default:
            return state;
    }
};

export const DriversTable = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDrivers();
        const interval = setInterval(fetchDrivers, PAGE_REFRESH_INTERVAL);
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [state.page, state.rowsPerPage, state.filter, state.sortColumn, state.sortOrder]);

    const fetchDrivers = () => {
        driversService
            .getByFilter({
                page: state.page,
                pageSize: state.rowsPerPage,
                employeeNumber: state.filter.employeeNumber,
                fio: state.filter.fio,
                companyId: state.filter.company?.id || null,
                departmentId: state.filter.department?.id || null,
                hideDismissed: state.filter.hideDismissed,
                sortColumn: state.sortColumn,
                sortOrder: state.sortOrder,
            })
            .then((r) => {
                dispatch({ type: 'SET_DRIVERS', payload: { drivers: r.data.data.items, total: r.data.data.count } });
            })
            .catch((e) => console.error(e));
    };

    const handlePageChange = (_: any, newPage: number) => {
        dispatch({ type: 'SET_PAGE', payload: newPage });
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE', payload: parseInt(event.target.value, 10) });
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'SET_FILTER', payload: { [event.target.name]: event.target.value } });
    };

    const handleSort = (column: string) => {
        const isAsc = state.sortColumn === column && state.sortOrder === SortingOrder.Asc;
        dispatch({ type: 'SET_SORT', payload: { column, order: isAsc ? SortingOrder.Desc : SortingOrder.Asc } });
    };

    const renderSortLabel = (column: string, label: string) => (
        <TableSortLabel
            active={state.sortColumn === column}
            direction={state.sortColumn === column ? state.sortOrder : SortingOrder.Asc}
            onClick={() => handleSort(column)}
        >
            <Typography variant="body1" sx={{ color: "#7c7e92" }}>{label}</Typography>
        </TableSortLabel>
    );

    const handleRowClick = (_: React.MouseEvent, driverId: number) => {
        navigate(ROUTES.drivers.idPath.replace(':id', String(driverId)));
    };

    const renderDismissed = (result: DriverShortDto) => {
        const strValue = result.isDismissed ? "Уволен" : "Работает";
        let color = result.isDismissed ? '#ec4a71' : '#5bba64';
        let backgroundColor = result.isDismissed ? '#fce4ea' : '#edfbee';

        return <Box display="inline-block" color={color} sx={{ backgroundColor: { backgroundColor }, borderRadius: "25px", paddingLeft: "15px", paddingRight: "15px" }}>{strValue}</Box>;
    };

    const handleCompanyChange = (newCompany: CompanyShortDto | null) => {
        dispatch({ type: 'SET_FILTER', payload: { company: newCompany } })
    };
    
    const handleDepartmentChange = (newDepartment: DepartmentShortDto | null) => {
        dispatch({ type: 'SET_FILTER', payload: { department: newDepartment } })
    };

    return (
        <Box>
            <Grid container pb={1} mt={2.5}>
                <Grid item xs={8} style={{ display: 'flex', alignItems: 'left' }}>
                    <TextField
                        label="Табельный №"
                        name="employeeNumber"
                        value={state.filter.employeeNumber}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => dispatch({ type: 'SET_PAGE', payload: 0 })}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        label="ФИО"
                        name="fio"
                        value={state.filter.fio}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => dispatch({ type: 'SET_PAGE', payload: 0 })}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <CompanyAutocomplete
                        company={state.filter.company}
                        onCompanyChange={handleCompanyChange}
                        style={{ width: 200, marginRight: 10 }}
                    />

                    <DepartmentAutocomplete
                        company={state.filter.company}
                        department={state.filter.department}
                        onDepartmentChange={handleDepartmentChange}
                        style={{ width: 200, marginRight: 10 }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="hideDismissedCheckbox"
                                checked={state.filter.hideDismissed || false}
                                onChange={(event) => dispatch({
                                    type: 'SET_FILTER',
                                    payload: { hideDismissed: event.target.checked ? event.target.checked : null },
                                })}
                                style={{ color: '#7E5EA9' }}
                            />
                        }
                        label="Скрыть уволенных"
                    />
                </Grid>

                <Grid item xs={4} style={{ display: 'flex', alignItems: 'right' }}>
                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Add}>
                        <IconButton sx={{ marginRight: 1, minWidth: 250 }} className="list-item" onClick={() => dispatch({ type: 'TOGGLE_ADD_POPUP' })}>
                            <TmPlus fill="none" stroke="white" />&nbsp;
                            <Typography fontWeight="bold" pr={1}>
                                &nbsp;Добавить
                            </Typography>
                        </IconButton>
                    </ShowForPermission>
                    <ShowForPermission catalog={CategoryType.Drivers} permission={PermissionType.Add}>
                        <IconButton className="list-item" onClick={() => dispatch({ type: 'TOGGLE_IMPORT_POPUP' })}>
                            <TmPlus fill="none" stroke="white" />&nbsp;
                            <Typography fontWeight="bold" pr={1}>
                                &nbsp;Импортировать из Excel
                            </Typography>
                        </IconButton>
                    </ShowForPermission>

                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{renderSortLabel('employeeNumber', 'Табельный №')}</TableCell>
                            <TableCell>{renderSortLabel('lastName', 'Фамилия')}</TableCell>
                            <TableCell>{renderSortLabel('firstName', 'Имя')}</TableCell>
                            <TableCell>{renderSortLabel('middleName', 'Отчество')}</TableCell>
                            <TableCell>{renderSortLabel('birthDate', 'Дата рождения')}</TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92" }}>Клиент</Typography></TableCell>
                            <TableCell><Typography variant="body1" sx={{ color: "#7c7e92", textAlign: "center" }}>Кол-во объектов</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.drivers.map((driver) => (
                            <TableRow
                                key={driver.id}
                                onClick={(event) => handleRowClick(event, driver.id)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.employeeNumber}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.lastName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.firstName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.middleName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">{DateUtils.formatDate(driver.birthDate)}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{driver.companyName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">{driver.departmentsCount}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{renderDismissed(driver)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {state.total}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={state.total}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>

            <EditDriverModal
                open={state.isAddPopupOpen}
                onClose={() => dispatch({ type: 'TOGGLE_ADD_POPUP' })}
                onRefresh={fetchDrivers} isAdding={true} />
            <ImportDriversDialog
                open={state.isImportPopupOpen}
                onClose={() => dispatch({ type: 'TOGGLE_IMPORT_POPUP' })}
                onRefresh={fetchDrivers}
            />
        </Box>
    );
};
