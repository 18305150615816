import React, { useEffect, useState, forwardRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { departmentsService } from '../../services/departmentsService';
import { DepartmentShortDto } from '../../models/departments/departmentShortDto';
import { CompanyShortDto } from '../../models/companies/companyShortDto';

interface DepartmentAutocompleteProps {
    company: CompanyShortDto | null;
    department: DepartmentShortDto | null;
    onDepartmentChange: (newDepartment: DepartmentShortDto | null) => void;
    label?: string;
    style?: React.CSSProperties;
}

const DepartmentAutocomplete = forwardRef<HTMLDivElement, DepartmentAutocompleteProps>(
    ({ company, department, onDepartmentChange, label = "Объект", style = {} }, ref) => {
        const [departments, setDepartments] = useState<DepartmentShortDto[]>([]);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            if (company) {
                setLoading(true);
                departmentsService.getAvailable(company.id)
                    .then(response => {
                        setDepartments(response.data.data);
                    })
                    .catch(error => {
                        console.error('Error fetching departments:', error);
                        setDepartments([]);
                    })
                    .finally(() => setLoading(false));
            } else {
                setDepartments([]);
                onDepartmentChange(null);
            }
        }, [company]);

        const handleChange = (_: React.SyntheticEvent, newValue: DepartmentShortDto | null) => {
            onDepartmentChange(newValue);
        };

        return (
            <Autocomplete
                options={departments}
                getOptionLabel={(option) => option.name}
                value={department}
                onChange={handleChange}
                noOptionsText={company ? "Не найдено" : "Сначала выберите компанию"}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={!company || loading}
                ref={ref}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={style}
                    />
                )}
            />
        );
    }
);

export default DepartmentAutocomplete;
