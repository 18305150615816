import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Box, Typography, TextField, IconButton, InputAdornment, Grid, TableSortLabel, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { dispatchersService } from '../../services/dispatchersService';
import { SortingOrder } from '../../models/enums/sortingOrder';
import { DispatcherShortInfoDto } from '../../models/dispatchers/dispatcherShortInfoDto';
import { ROUTES } from '../../constants/routes';
import AcceptLabel from '../common/labels/AcceptLabel';
import DenyLabel from '../common/labels/DenyLabel';
import { CompanyShortDto } from '../../models/companies/companyShortDto';
import CompanyAutocomplete from '../common/CompanyAutocomplete';
import TmPlus from '../../icons/TmPlus';
import { CategoryType } from '../../models/enums/categoryType';
import { PermissionType } from '../../models/enums/permissionType';
import { ShowForPermission } from '../common/permissions/ShowForPermission';
import { EditDispatcherModal } from './edit/EditDispatcherModal';

const PAGE_REFRESH_INTERVAL = 10000;

export const DispatchersTable = () => {
    const [isAddPopupOpen, setAddPopupOpen] = useState(false);
    const [state, setState] = useState({
        dispatchers: [] as DispatcherShortInfoDto[],
        page: 0,
        rowsPerPage: 15,
        dataCount: 0,
        loading: true,
        sortColumn: 'id',
        sortOrder: SortingOrder.Asc,
        loadingColumn: null as string | null,
        fio: '',
        company: null as CompanyShortDto | null,
        hideDismissed: null as boolean | null,
        shouldFetch: true,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (state.shouldFetch) {
            fetchDispatchers();
            setState(prevState => ({
                ...prevState,
                shouldFetch: false,
            }));
        }
        const interval = setInterval(fetchDispatchers, PAGE_REFRESH_INTERVAL);
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [state.page, state.rowsPerPage, state.shouldFetch]);

    const fetchDispatchers = () => {
        setState(prevState => ({ ...prevState, loading: true }));

        dispatchersService.getByFilter({
            page: state.page,
            pageSize: state.rowsPerPage,
            fio: state.fio,
            companyId: state.company ? state.company.id : undefined,
            hideDismissed: state.hideDismissed ? state.hideDismissed : undefined,
            sortColumn: state.sortColumn,
            sortOrder: state.sortOrder,
        })
            .then(r => {
                setState(prevState => ({
                    ...prevState,
                    dispatchers: r.data.data.items,
                    dataCount: r.data.data.count,
                }));
            })
            .catch(e => console.log(e))
            .finally(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    loadingColumn: null,
                }));
            });
    };

    const handleChangePage = (event: any, newPage: number) => {
        setState(prevState => ({
            ...prevState,
            page: newPage,
            shouldFetch: true,
        }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
            page: 0,
            shouldFetch: true,
        }));
    };

    const handleCompanyChange = (newCompany: CompanyShortDto | null) => {
        setState(prevState => ({
            ...prevState,
            company: newCompany,
            shouldFetch: true,
        }));
    };

    const handleAddPopupToggle = () => {
        setAddPopupOpen(!isAddPopupOpen);
    };

    const handleRowClick = (event: React.MouseEvent, dispatcherId: number) => {
        const url = ROUTES.dispatchers.idPath.replace(':id', String(dispatcherId));
        navigate(url);
    };

    const handleSort = (column: string) => {
        const isAsc = state.sortColumn === column && state.sortOrder === SortingOrder.Asc;
        setState(prevState => ({
            ...prevState,
            sortColumn: column,
            sortOrder: isAsc ? SortingOrder.Desc : SortingOrder.Asc,
            loadingColumn: column,
            shouldFetch: true,
        }));
    };

    const renderTableSortLabel = (column: string, label: string) => (
        <TableSortLabel
            active={state.sortColumn === column}
            direction={state.sortColumn === column ? state.sortOrder : SortingOrder.Asc}
            onClick={() => handleSort(column)}
            IconComponent={state.loadingColumn === column ? () => <CircularProgress size={12} sx={{ color: '#9588e8' }} /> : undefined}
        >
            <Typography variant="body1" sx={{ color: "#7c7e92" }}>{label}</Typography>
        </TableSortLabel>
    );

    return (
        <Box>
            <Grid container pb={1} mt={2.5}>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="ФИО"
                        name="fio"
                        value={state.fio}
                        onChange={handleFilterChange}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{ width: 200, marginRight: 10 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setState(prevState => ({ ...prevState, page: 0, shouldFetch: true }))}>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "10px",
                            }
                        }}
                    />

                    <CompanyAutocomplete company={state.company} onCompanyChange={handleCompanyChange} style={{ width: 200, marginRight: 10 }} />

                    <FormControlLabel
                        control={
                            <Checkbox
                                id="hideDismissedCheckbox"
                                checked={state.hideDismissed || false}
                                onChange={(event) => setState(prevState => ({
                                    ...prevState,
                                    hideDismissed: event.target.checked ? event.target.checked : null,
                                    shouldFetch: true,
                                }))}
                                style={{ color: '#7E5EA9' }}
                            />
                        }
                        label="Скрыть уволенных"
                    />
                </Grid>
                <Grid item xs={2} style={{ display: 'inline-block', alignItems: 'center' }}>
                    <ShowForPermission catalog={CategoryType.Dispatchers} permission={PermissionType.Add}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={handleAddPopupToggle} >
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Добавить
                                </Typography>
                            </IconButton>
                        </Box>
                    </ShowForPermission>
                </Grid>
            </Grid>

            <TableContainer sx={{ '.MuiTableCell-root': { py: 1.5 } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('lastName', 'Фамилия')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('firstName', 'Имя')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('middleName', 'Отчество')}
                            </TableCell>
                            <TableCell sx={{ width: '25%' }}>
                                {renderTableSortLabel('companies', 'Клиенты')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('departmentsCount', 'Кол-во объектов')}
                            </TableCell>
                            <TableCell sx={{ width: '15%' }}>
                                {renderTableSortLabel('isDismissed', 'Статус')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.dispatchers.map((dispatcher) => (
                            <TableRow
                                key={dispatcher.id}
                                onClick={(event) => handleRowClick(event, dispatcher.id)}
                                sx={{ '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(0, 0, 0, 0.08)' } }}
                            >
                                <TableCell sx={{ fontWeight: "bold" }}>{dispatcher.lastName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{dispatcher.firstName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{dispatcher.middleName}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{dispatcher.companies.length > 0 ? dispatcher.companies.map(d => d.name).join(', ') : '<Отсутствует>'}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{dispatcher.departmentsCount}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{dispatcher.isDismissed ? <DenyLabel>Уволен</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>Всего: {state.dataCount}</Typography>
                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component="div"
                    count={state.dataCount}
                    rowsPerPage={state.rowsPerPage}
                    page={state.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Строк на странице:"}
                    labelDisplayedRows={({ from, to, count }: any) => `Показано ${from}-${to} из ${count}`}
                />
            </Box>
            <EditDispatcherModal onClose={handleAddPopupToggle} onRefresh={fetchDispatchers} isAdding={true} open={isAddPopupOpen} />
        </Box>
    );
};