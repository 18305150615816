import React, { forwardRef, useEffect, useState } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { terminalsService } from '../../services/terminalsService';
import { LookupDto } from '../../models/common/lookup.dto';

interface TerminalsAutocompleteProps {
    terminals: LookupDto[] | null;
    onTerminalsChange: (newTerminals: LookupDto[]) => void;
    label?: string;
    style?: React.CSSProperties;
    multiple?: boolean;
}

const TerminalsAutocomplete = forwardRef<HTMLDivElement, TerminalsAutocompleteProps>(({ terminals, onTerminalsChange, label = "Терминалы", style = {}, multiple = false }, ref) => {
    const [options, setOptions] = useState<LookupDto[]>([]);
    const [initialOptions, setInitialOptions] = useState<LookupDto[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        terminalsService.search({
            nameWithAddress: '',
            page: 0,
            pageSize: 10,
        })
            .then(response => {
                setOptions(response.data.data.items);
                setInitialOptions(response.data.data.items);
            })
            .catch(error => {
                console.error('Error fetching terminals:', error);
            })
            .finally(() => setLoading(false));
    }, []);

    const handleInputChange = (_: React.SyntheticEvent, newInputValue: string) => {
        if (newInputValue.length >= 3) {
            setLoading(true);
            terminalsService.search({
                nameWithAddress: newInputValue,
                page: 0,
                pageSize: 10,
            })
                .then(response => {
                    setOptions(response.data.data.items);
                })
                .catch(error => {
                    console.error('Error fetching terminals:', error);
                    setOptions([]);
                })
                .finally(() => setLoading(false));
        } else {
            setOptions(initialOptions);
        }
    };

    const handleChange = (_: React.SyntheticEvent, newValue: LookupDto | LookupDto[] | null) => {
        onTerminalsChange(Array.isArray(newValue) ? newValue : (newValue ? [newValue] : []));
    };

    return (
        <Autocomplete
            multiple={multiple}
            options={options}
            getOptionLabel={(option) => option.name}
            value={terminals || []}
            onChange={handleChange}
            onInputChange={handleInputChange}
            noOptionsText="Не найдено"
            loadingText="Поиск..."
            isOptionEqualToValue={(option, value) => option.id === value.id}
            ref={ref}
            loading={loading}
            disableClearable={multiple}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    style={{ ...style, minWidth: '200px' }}
                    placeholder="Введите от 3х букв"
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingRight: '8px' },
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: 3 }} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
});

export default TerminalsAutocomplete; 